<template>
  <fieldset>
    <DocFieldsZu
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.landUseBoundary"
          @update:modelValue="update('landUseBoundary', $event)"
          :options="vectors"
          :itemValidationRule="itemValidationRule"
          @reload="fetchWithDelay"
          data-qa="landUseLimit"
          keyProp="externalId"
        >
          <label class="label">Границы использования земли</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsZu>
  </fieldset>
</template>

<script>
import MultiSelectField from "../../basic/MultiSelectField.vue";
import DocFieldsZu from "./DocFieldsZu.vue";
import DocResolutionUseLand from "../../../models/documents/DocResolutionUseLand";
import { toRefs } from "vue";
import { useVectorLandBoundary } from "../../../hooks/vector";
import { helpers } from "@vuelidate/validators";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocResolutionUseLand(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: {
    DocFieldsZu,
    MultiSelectField,
  },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { vectors, fetchWithDelay } = useVectorLandBoundary();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let itemValidationRule = {
      externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
        return value !== null && value["externalId"] !== undefined
      })
    }

    return { data, update, vectors, fetchWithDelay, itemValidationRule };
  },
};
</script>